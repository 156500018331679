//import logo from './logo.svg';
import { useEffect } from 'react'
import './App.css'
import chatgpt from './chatgpt.png'
import logo from './images/logo.svg'
import Post from './components/Post.js'
import data from './content.json'
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom'
import posts from './posts.json'

//posts.map(

function Home() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/algorithms-and-attention') 
  }, [])
  return (<div></div>)
}

const routes = Object.keys(data).map(key => {
  const { content, author, date, title } = data[key]
  return {
    path: key,
    element: <Post title={title} author={author} date={date} content={content} />
  }
})

const router = createBrowserRouter([
  ...routes,
  {
    path: "/",
    element: <Home />

  }
])

function App() {
  
  return (
    <div className="App">
      <img className="title" style={{ width: '100px', height: 'auto' }} src={logo} />
      {/* <Page author={'David J Kamer'} date={'May 16th, 2023'} content={content} /> */}
      <RouterProvider router={router} /> 
    </div>
  );
}

export default App;
