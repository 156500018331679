//import chatgpt from '../chatgpt.png'
//import chatgpt from '../chatgpt.png'
import posts from '../posts.json'

const linker = ({ links, content }) => {
  let leftText = content
  const items = Object.keys(links).map((linkText, i) => {
    const surroundingText = leftText.split(linkText)
    leftText = surroundingText[1]
    return [<span key={`${i}-span`} style={{ color: 'black' }}>{surroundingText[0]}</span>, <a key={`${i}-a`} href={links[linkText]}>{linkText}</a>]
  })
  return [...items, <span key={btoa(leftText.slice(0, 5))}>{leftText}</span>]
}

function H2({ content }) {
  return <h2>{content}</h2>
}

function Paragraph({ links, content }) {
  const items = links ? linker({ links, content }) : [content]
  return <p>{items.map(item => item)}</p>
}

function Figcaption({ links, content }) {
  const items = links ? linker({ links, content }) : [content]
  return <figcaption>{items.map(item => item)}</figcaption>
}

function Table({ content: { head, body } }) {
  return (<table>
    <thead><tr>{head.map(item => <th>{item}</th>)}</tr></thead>
    <tbody>
      {body.map(row => {
        return (<tr>{row.map(data => <td>{data}</td>)}</tr>)
      })}
    </tbody>
  </table>)
}

function OrderedList({ content: { array } }) {
    return (<ol style={{ textAlign: "left" }}>
    {
      array.map((item, i) => {
        return Array.isArray(item)
          ? <ol type="a"  key={i}>{ item.map((item_, i) => <li key={i}>{ item_ }</li>) }</ol>
          : <li key={i}>{ item }</li> 
      })
    }
    </ol>)
}

function Post({ author, date, content, title, route: current }) {
  return (<div>
      <h1 className="article-title">{title}</h1>
      <div className="container">
      <div className="side-bar">
        <div>
        { author }
        </div>
        <div>
          { date }
        </div>
      </div>
      <div className="body">
        {
          content.map(({ content, type, links, style }, i) => {
            if (type === 'table') {
              return <Table content={content} />
            }
            if (type === 'figcaption') {
              return <Figcaption key={i} links={links} content={content} />
            }
            if (type === 'h2') {
              return <H2 content={content} /> 
            }
            if (type === 'br') {
              return <br />
            }
            if (type === 'ol') {
              return <OrderedList content={content} />
            }
            return ((type === 'p') && <Paragraph key={i} links={links} content={content} /> || ((type === 'img') && (<img key={i} style={{ width: '60vw', height: 'auto', ...(style ? style : {}) }}
              src={require(`../${content}.png`)} />))
          )
        })   
        }
      </div>
      <nav>
        {
          posts.map(({ name, route }) => {
            if (route !== current) return <a key={route} href={route}> {name} </a>
          })
        }
      </nav>
    </div>
    </div>)

}

export default Post
